import { MouseEvent, useCallback } from 'react'

import { Button, Grid } from '@pluggyai/ui'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Props } from './Navbar.types'
import { TrackEventName } from '../../lib/analytics/events'
import { track } from '../../lib/analytics/utils'
import { locations } from '../../lib/locations'

import styles from './Navbar.module.css'

const Navbar = ({
  landingPageFlag,
  rightSideText,
  rightSideUrl = '/api/auth/logout',
  leftSideUrl = '/',
  divisorLine = false,
  onClick,
}: Props) => {
  const Router = useRouter()

  const { pathname, push } = Router

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!onClick) {
        push(`${rightSideUrl}`)
        return
      }
      onClick(event)

      track(TrackEventName.BUTTON_CLICKED, {
        location: 'Navbar',
        text: event.currentTarget.innerText,
      })
    },
    [onClick, push, rightSideUrl]
  )

  return (
    <>
      {landingPageFlag ? (
        <Grid className={`${styles.navbar_layout} relative z-50`}>
          <Grid.Row columns={2} className={styles.navbar_row_lading}>
            <Grid.Column className={styles.left_side_layout}>
              <Link href={`${leftSideUrl}`}>
                <div className={styles.text_meu}>
                  <Image
                    className="mp-img"
                    src="/icons/my-pluggy-logo.svg"
                    alt="Logo"
                    width={162}
                    height={32}
                  />
                </div>
              </Link>
            </Grid.Column>
            <Grid.Column className={styles.text_login}>
              <Button
                className={styles.button_login}
                secondary
                onClick={handleClick}
              >
                {rightSideText}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <nav className="w-full flex flex-row items-center justify-between px-10">
          <Link href={leftSideUrl}>
            <div className={styles.text_meu}>
              <Image
                className="mp-img"
                src="/icons/my-pluggy-logo.svg"
                alt="Logo"
                width={162}
                height={32}
              />
            </div>
          </Link>
          <div className="flex flex-row gap-6 align-center">
            <Link
              href={locations.insights()}
              className={`p-2 text-pink-400 hover:text-pink-400 hover:no-underline hover:bg-pink-100 rounded-md ${
                pathname === locations.insights() ? 'bg-pink-100' : ''
              }`}
            >
              Insights
            </Link>
            <Link
              href={locations.account()}
              className={`p-2 text-pink-400 hover:text-pink-400 hover:no-underline hover:bg-pink-100 rounded-md ${
                pathname === locations.account() ? 'bg-pink-100' : ''
              }`}
            >
              Contas
            </Link>
          </div>
          <Button
            className={styles.text_voltar_account}
            text
            onClick={handleClick}
          >
            {rightSideText}
          </Button>
        </nav>
      )}
      {divisorLine ? <hr className={styles.solid}></hr> : <> </>}
    </>
  )
}

export default Navbar
