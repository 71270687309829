import { memo, useCallback } from 'react'

import { Button } from '@pluggyai/ui'

import { TrackEventName } from '../../lib/analytics/events'
import { track } from '../../lib/analytics/utils'
import { showIntercom } from '../../lib/intercom'

import styles from './ContactButton.module.css'

type Props = {
  text: string
  location?: string
  contactPresetMessage?: string
  link?: boolean
  className?: string
}

const ContactButton = ({
  text: contactLinkText,
  contactPresetMessage,
  link,
  className,
  location = '',
}: Props) => {
  const handleContactButtonClick = useCallback(() => {
    showIntercom(contactPresetMessage)

    track(TrackEventName.BUTTON_CLICKED, {
      contactPresetMessage,
      text: contactLinkText,
      location: `${location} ContactButton`,
    })
  }, [contactLinkText, contactPresetMessage, location])

  const classNameCombined = [styles.contactButton, className || ''].join(' ')

  return (
    <Button
      link={link}
      className={classNameCombined}
      onClick={handleContactButtonClick}
    >
      {contactLinkText}
    </Button>
  )
}

export default memo(ContactButton)
