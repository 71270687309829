import { MouseEvent, useCallback, useState } from 'react'

import { Button, Grid } from '@pluggyai/ui'
import Image from 'next/image'
import Link from 'next/link'

import { ContactButton } from '../components/ContactButton'
import { FAQAccordion } from '../components/FAQAccordion'
import { Navbar } from '../components/Navbar'
import { Page } from '../components/Page'
import { TextCard } from '../components/TextCard'
import { TrackEventName } from '../lib/analytics/events'
import { track } from '../lib/analytics/utils'
import { locations } from '../lib/locations'

const PLUGGY_HOME_URL = 'https://www.pluggy.ai'

const HomePage = (): JSX.Element => {
  const [expanded, setExpanded] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])

  const toggleFaqCards = (cardId: number) => {
    const faqCardCount = expanded.length
    const selectedCardExpanded = expanded[cardId]
    const faqExpandedArray = new Array(faqCardCount).fill(false)

    if (selectedCardExpanded) {
      faqExpandedArray[cardId] = false
    } else {
      faqExpandedArray[cardId] = true
    }
    setExpanded(faqExpandedArray)
  }

  const handleCreateAccountButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      track(TrackEventName.BUTTON_CLICKED, {
        location: 'Landing Page',
        text: event.currentTarget.innerText,
      })
    },
    []
  )

  const handleCreateAccountClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      track(TrackEventName.BUTTON_CLICKED, {
        location: 'Landing Page',
        text: event.currentTarget.innerText,
      })
    },
    []
  )

  const handlePluggyLinkClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      track(TrackEventName.LINK_CLICKED, {
        location: 'Landing Page',
        link: PLUGGY_HOME_URL,
        text: event.currentTarget.innerText,
      })
    },
    []
  )

  const handleLoginButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      track(TrackEventName.BUTTON_CLICKED, {
        location: 'Landing Page',
        text: event.currentTarget.innerText,
      })
    },
    []
  )

  return (
    <Page>
      <div className="lp-section">
        <Grid className="page-grid">
          <div className="navbar-desktop">
            <Navbar
              landingPageFlag
              rightSideText="Fazer Login"
              rightSideUrl={locations.login()}
            />
          </div>
          <div className="navbar-mobile">
            <Navbar
              landingPageFlag
              rightSideText="Log in"
              rightSideUrl={locations.login()}
            />
          </div>
        </Grid>
        <Grid style={{ marginTop: 64 }} stackable>
          <Grid.Row columns={2} centered className="footer">
            <Grid.Column textAlign="left">
              <Grid.Row className="top-title-landingpage-row">
                <h1 className="top-title-landingpage">
                  Todas suas contas em um único lugar!
                </h1>
              </Grid.Row>
              <Grid.Row className="top-desc-landingpage">
                Conecte-se a serviços com um clique, gerencie permissões, receba
                insights de todas as suas contas e serviços.
                <br></br>Meu Pluggy é o seu HUB de conexões.
              </Grid.Row>
              <Grid centered>
                <Grid.Row columns={2}>
                  <div className="group-bt">
                    <Grid.Row className="bt-row">
                      <div className="create-acount-bt">
                        <Link href={locations.login()}>
                          <Button
                            className="create-account-bt-lp"
                            primary
                            size="medium"
                            onClick={handleCreateAccountButtonClick}
                          >
                            Criar conta
                          </Button>
                        </Link>
                      </div>
                    </Grid.Row>
                    <Grid.Row className="contact-bt-row">
                      <div className="contact-bt">
                        <Grid.Row>Tem alguma dúvida? </Grid.Row>
                        <Grid.Row>
                          <ContactButton
                            link
                            text={'Fale com a gente!'}
                            location={'HomePage'}
                            contactPresetMessage={
                              'Olá! Gostaria de saber mais sobre o Meu Pluggy! Pode me ajudar?'
                            }
                          />
                        </Grid.Row>
                      </div>
                    </Grid.Row>
                  </div>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid.Row columns={3}>
                <img
                  className="gradient-class"
                  src="/assets/gradient1.svg"
                  width="640"
                  height="214"
                  alt="gradiente-background"
                />
                <Grid.Column>
                  <div className="account-info-mobile">
                    <img
                      className="account-mobile-img"
                      src="/icons/account_mobile.svg"
                      alt="account_mobile"
                    />
                  </div>
                  <div className="connector-list">
                    <p className="conected-acount-text">Contas Conectadas</p>
                    <Grid.Row columns={2}>
                      <div className="bank-comp">
                        <Grid.Column>
                          <div className="connector-card">
                            <img
                              className="bb-image"
                              src="/assets/bbBank.svg"
                              width="160"
                              height="44"
                              alt="bb-bank"
                            />
                            <hr className="hr-bank" />
                            <span className="last-update-bank">
                              Última Atualização:
                            </span>
                            <span className="result-update-bank">Hoje</span>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <img
                            className="fb"
                            src="/icons/contextual-menu.svg"
                            width="4"
                            height="14"
                            alt="contextual-menu1"
                          />
                        </Grid.Column>
                      </div>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <div className="bank-comp">
                        <Grid.Column>
                          <div className="connector-card">
                            <img
                              className="bb-image"
                              src="/assets/interBank.svg"
                              width="140"
                              height="44"
                              alt="interbank"
                            />
                            <hr className="hr-bank" />
                            <span className="last-update-bank">
                              Última Atualização:
                            </span>
                            <span className="result-update-bank">
                              2 dias atrás
                            </span>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <img
                            className="fb"
                            src="/icons/contextual-menu.svg"
                            width="4"
                            height="14"
                            alt="contextual-menu2"
                          />
                        </Grid.Column>
                      </div>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <div className="bank-comp">
                        <Grid.Column>
                          <div className="connector-card">
                            <img
                              className="bb-image"
                              src="/assets/nubankBank.svg"
                              width="110"
                              height="44"
                              alt="nubank"
                            />
                            <hr className="hr-bank" />
                            <span className="last-update-bank">
                              Última Atualização:
                            </span>
                            <span className="result-update-bank">
                              1 dia atrás
                            </span>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <img
                            className="fb"
                            src="/icons/contextual-menu.svg"
                            width="4"
                            height="14"
                            alt="contextual-menu3"
                          />
                        </Grid.Column>
                      </div>
                    </Grid.Row>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className="connector-list-tablet">
                    <p className="conected-acount-text">Contas Conectadas</p>
                    <Grid.Row columns={2}>
                      <div className="bank-comp">
                        <Grid.Column>
                          <div className="connector-card">
                            <img
                              className="bb-image"
                              src="/assets/bbBank.svg"
                              width="160"
                              height="44"
                              alt="bb-bank2"
                            />
                            <hr className="hr-bank" />
                            <span className="last-update-bank">
                              Última Atualização:
                            </span>
                            <span className="result-update-bank">Hoje</span>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <img
                            className="fb"
                            src="/icons/contextual-menu.svg"
                            width="4"
                            height="14"
                            alt="contextual-menu4"
                          />
                        </Grid.Column>
                      </div>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <div className="bank-comp">
                        <Grid.Column>
                          <div className="connector-card">
                            <img
                              className="bb-image"
                              src="/assets/interBank.svg"
                              width="140"
                              height="44"
                              alt="interbank2"
                            />
                            <hr className="hr-bank" />
                            <span className="last-update-bank">
                              Última Atualização:
                            </span>
                            <span className="result-update-bank">
                              2 dias atrás
                            </span>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <img
                            className="fb"
                            src="/icons/contextual-menu.svg"
                            width="4"
                            height="14"
                            alt="contextual-menu5"
                          />
                        </Grid.Column>
                      </div>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <div className="bank-comp">
                        <Grid.Column>
                          <div className="connector-card">
                            <img
                              className="bb-image"
                              src="/assets/nubankBank.svg"
                              width="110"
                              height="44"
                              alt="nubank2"
                            />
                            <hr className="hr-bank" />
                            <span className="last-update-bank">
                              Última Atualização:
                            </span>
                            <span className="result-update-bank">
                              1 dia atrás
                            </span>
                          </div>
                        </Grid.Column>
                        <Grid.Column>
                          <img
                            className="fb"
                            src="/icons/contextual-menu.svg"
                            width="4"
                            height="14"
                            alt="contextual-menu6"
                          />
                        </Grid.Column>
                      </div>
                    </Grid.Row>
                  </div>
                  <div className="account-comp">
                    <div className="download-div">
                      <Grid.Row>
                        <img
                          className="download-icon"
                          src="/icons/download_icon.svg"
                          alt="download-icon"
                        />
                      </Grid.Row>
                      <Grid.Row>
                        <span className="download-text"> Baixar</span>
                      </Grid.Row>
                      <Grid.Row>
                        <div className="up-line" />
                      </Grid.Row>
                      <Grid.Row>
                        <img
                          className="trash-icon"
                          src="/icons/trash-icon.svg"
                          alt="trash-icon"
                        />
                      </Grid.Row>
                    </div>
                    <Grid.Row>
                      <div className="identity-card">
                        <h4 className="detalhe-text">Detalhes Integrados</h4>
                        <Grid.Row>
                          <div className="identity-comp">
                            <img
                              width="24"
                              height="24"
                              className="identity-icon"
                              src="/icons/detail_squad.svg"
                              alt="datail-squad"
                            />
                            <span className="label">Identidade |</span>
                            <span className="value">Diego Souza</span>
                          </div>
                          <hr className="line-c-card-id" />
                        </Grid.Row>
                        <Grid.Row>
                          <div className="link-text-cc">
                            <span>Mostrar cartões de crédito</span>
                            <img
                              className=""
                              src="/icons/carrot-down.svg"
                              width="18"
                              height="18"
                              alt="carrot-down1"
                            />
                          </div>
                          <hr className="line-c-card-id" />
                          <div className="link-text-i">
                            <span>Mostrar Investimentos</span>
                            <img
                              className=""
                              src="/icons/carrot-down.svg"
                              width="18"
                              height="18"
                              alt="carrot-down2"
                            />
                          </div>
                        </Grid.Row>
                      </div>
                    </Grid.Row>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="card-lp">
          <Grid stackable className="card-grid">
            <Grid.Row className="card-title-row">
              <h3 className="card-title">O que nós podemos fazer por você?</h3>
              <div className="arrow">
                <div className="line"></div>
                <div className="point"></div>
              </div>
            </Grid.Row>
            <Grid.Row columns={3} className="card-cell-row">
              <Grid.Column className="cell-right-border">
                <div className="card-cell">
                  <TextCard
                    image="/icons/landing-page-lock.svg"
                    title="Backup da conta"
                    description="Quer saber como encerrar sua conta no banco sem perder seus dados? Fácil! Crie a conta com o Meu Pluggy, conecte sua conta e faça o backup!"
                    url={locations.login()}
                    urlText="Quero fazer meu backup!"
                  />
                </div>
              </Grid.Column>
              <Grid.Column className="cell-right-border">
                <div className="card-cell">
                  <TextCard
                    image="/icons/landing-page-lock.svg"
                    title="Gerencie suas permissões"
                    description="Nada de ter dados compartilhados e nem saber onde. A partir do Meu Pluggy você poderá saber quais permissões você deu e gerenciá-las. Ou seja, você pode revogar qualquer uma dessas permissões quando quiser."
                    url={locations.login()}
                    urlText="Criar conta"
                  />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="card-cell">
                  <TextCard
                    image="/icons/landing-page-lock.svg"
                    title="Insights de verdade"
                    description="Por meio dos seus dados, a Pluggy conhece seu perfil financeiro e te dá insights personalizados, como onde gastar menos, dicas de economia e muito mais."
                    url={locations.login()}
                    urlText="Estou interessado"
                    beta
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className="info">
          <Grid stackable>
            <Grid.Row className="card-title-info">
              <div className="card-title-div">
                <h4>Perguntas frequentes</h4>
              </div>
            </Grid.Row>
            <Grid.Row className="subtitle">
              <h2 className={'title-doubts'}>Ainda está com dúvidas?</h2>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <FAQAccordion
                  id={0}
                  toggleFaqCards={toggleFaqCards}
                  expandedStatus={expanded}
                  title="Por que tenho que passar a minha senha? É seguro?"
                  description="A Pluggy precisa da sua senha para pedir a autorização de acesso à sua instituição financeira. Fique tranquilo, a tecnologia utilizada é muito segura, semelhante a utilizada por muitos bancos e nenhum ser humano tem acesso à sua senha. Além disso, não é possível realizar qualquer transação em seu nome através desta senha."
                />
                <FAQAccordion
                  id={1}
                  toggleFaqCards={toggleFaqCards}
                  expandedStatus={expanded}
                  title="Em que caso não devo compartilhar minha senha?"
                  description="Nunca compartilhe sua senha fora da plataforma. A Pluggy jamais solicitará sua senha por e-mail, WhatsApp, telefone ou qualquer outro meio de comunicação fora da plataforma."
                />
                <FAQAccordion
                  id={2}
                  toggleFaqCards={toggleFaqCards}
                  expandedStatus={expanded}
                  title="Quais informações a Pluggy terá acesso?"
                  description="Saldo de conta corrente; Saldo dos cartões de crédito; data de vencimento dos cartões de Crédito; Histórico Transacional."
                />
                <FAQAccordion
                  id={3}
                  toggleFaqCards={toggleFaqCards}
                  expandedStatus={expanded}
                  title="Posso conectar mais de uma conta? Isso me ajuda?"
                  description="Sim, deve! Caso você tenha mais de uma conta, é importantíssimo apresentá-las para ter uma melhor visão do seu perfil financeiro."
                />
                <FAQAccordion
                  id={4}
                  toggleFaqCards={toggleFaqCards}
                  expandedStatus={expanded}
                  title="Não tenho conta em nenhuma instituição. Posso participar?"
                  description="Infelizmente não. Para participar você precisa ter conta em uma das instituições financeiras listadas."
                />
              </Grid.Column>
              <Grid.Column>
                <div className="faq-mobile-margin-correct">
                  <FAQAccordion
                    id={5}
                    toggleFaqCards={toggleFaqCards}
                    expandedStatus={expanded}
                    title="Quais são as instituições financeiras listadas?"
                    description="Atualmente é possível conectar contas das seguintes instituições financeiras: Itaú, Bradesco, Santander, Banco do Brasil, Caixa, Nubank, Inter, Mercado Pago, Modal e também as corretoras: XP, BTG, Nuinvest, Ágora, Rico, Órama, Genial, Clear e B3 (CEI)."
                  />
                </div>
                <FAQAccordion
                  id={6}
                  toggleFaqCards={toggleFaqCards}
                  expandedStatus={expanded}
                  title="Por quanto tempo a Pluggy têm acesso às minhas informações?"
                  description="A partir do seu consentimento, teremos acesso aos dados durante 12 meses. Após esse período, o acesso às informações será cancelado e todas as informações serão excluídas de nossas bases de dados. Fique tranquilo, caso queira interromper o acesso durante esse período, você pode revogar seu consentimento enviando um e-mail para dpo@pluggy.ai com o seu nome completo, CPF, banco, agência e conta a serem deletados. Lembre-se: nesse e-mail você não deve informar a sua senha."
                />
                <FAQAccordion
                  id={7}
                  toggleFaqCards={toggleFaqCards}
                  expandedStatus={expanded}
                  title="Se mudar de ideia, consigo desconectar as minhas contas?"
                  description="Sim! Basta enviar um e-mail para dpo@pluggy.ai com o seu nome completo, CPF, banco, agência e conta a serem deletados. Lembre-se: nesse e-mail você não deve informar a sua senha."
                />
                <FAQAccordion
                  id={8}
                  toggleFaqCards={toggleFaqCards}
                  expandedStatus={expanded}
                  title="Recebi uma notificação de atividade suspeita. O que é isso"
                  description='Por motivos de segurança alguns bancos enviam um alerta de "atividade suspeita" quando uma nova solicitação de acesso à sua conta é realizada. Não se preocupe, a Pluggy acessou a sua conta com privacidade e segurança. A notificação foi enviada pois o acesso não veio do dispositivo padrão.'
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className="card-title-grid">
          <Grid.Row style={{ width: '100%' }}>
            <div className="card-title-start">
              <Grid.Column>
                <h2>Pronto para começar?</h2>
              </Grid.Column>
              <Grid.Column>
                <div className="bt-create-bottom">
                  <Link href={locations.login()}>
                    <Button primary onClick={handleCreateAccountClick}>
                      Criar conta
                    </Button>
                  </Link>
                </div>
              </Grid.Column>
            </div>
          </Grid.Row>
        </div>

        <hr className="divider" />
        <Grid stackable className="footer-grid">
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign="center">
              <div className="mp-logo-bottom">
                <Link href={locations.login()}>
                  <Button link onClick={handleLoginButtonClick}>
                    <Image
                      className="mp-img"
                      src="/icons/my-pluggy-logo.svg"
                      alt="Logo"
                      width={162}
                      height={32}
                    />
                  </Button>
                </Link>
              </div>
            </Grid.Column>
            <Grid.Column className="bottom-text-column">
              <Grid.Row className="bottom-text">
                <span>Produto Pluggy - </span>
                <Link href={PLUGGY_HOME_URL}>
                  <Button link onClick={handlePluggyLinkClick}>
                    {PLUGGY_HOME_URL}
                  </Button>
                </Link>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Page>
  )
}

// eslint-disable-next-line import/no-unused-modules
export default HomePage
