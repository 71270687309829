import { MouseEvent, useCallback } from 'react'

import { Button } from '@pluggyai/ui'
import Image from 'next/image'
import Link from 'next/link'

import { Props } from './TextCard.types'
import { TrackEventName } from '../../lib/analytics/events'
import { track } from '../../lib/analytics/utils'

import styles from './TextCard.module.css'

const TextCard = ({
  image,
  title = '',
  description,
  url,
  urlText,
  beta,
  children,
}: Props): JSX.Element => {
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    track(TrackEventName.LINK_CLICKED, {
      location: 'LandingPage TextCard',
      text: event,
    })
  }, [])

  return (
    <>
      {image && (
        <div className={styles.image}>
          <Image src={image} alt="Logo" layout="fill" objectFit="cover" />
        </div>
      )}
      <div className={styles.title_div}>
        <h4 className={styles.title}>{title}</h4>
        {beta && (
          <div className={styles.beta_div}>
            <div className={styles.beta_text}>Beta</div>
          </div>
        )}
      </div>
      <p className={styles.text}>{description}</p>
      {url && (
        <Link href={url}>
          <Button link onClick={handleClick}>
            <div className={styles.url_div}>
              <span className={styles.url}>{urlText ? urlText : url}</span>
              <img
                src="/icons/chevron-right.svg"
                alt="right-icon"
                width={8}
                height={17}
              />
            </div>
          </Button>
        </Link>
      )}
      {children}
    </>
  )
}

export default TextCard
