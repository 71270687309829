import Image from 'next/image'

import { Props } from './FAQAccordion.types'

import styles from './FAQAccordion.module.css'

const FAQAccordion = ({
  title,
  description,
  children,
  id,
  expandedStatus,
  toggleFaqCards,
}: Props): JSX.Element => {
  const handleAccordionClick = () => toggleFaqCards(id)

  return (
    <>
      <article className={styles.accordion}>
        <button className={styles.title_div} onClick={handleAccordionClick}>
          <h4 className={styles.title}>{title}</h4>
          <span className={styles.btn}>
            {expandedStatus[id] ? (
              <Image
                src="/icons/dash.svg"
                alt="right-icon"
                width={8}
                height={17}
                className={styles.icon}
              />
            ) : (
              <Image
                src="/icons/add-icon.svg"
                alt="right-icon"
                width={14}
                height={14}
                className={styles.icon}
              />
            )}
          </span>
          <span className={styles.btn_mobile}>
            {expandedStatus[id] ? (
              <Image
                src="/icons/dash.svg"
                alt="right-icon"
                width={29}
                height={38}
                className={styles.icon}
              />
            ) : (
              <Image
                src="/icons/add-icon.svg"
                alt="right-icon"
                width={35}
                height={35}
                className={styles.icon}
              />
            )}
          </span>
        </button>
        <div>
          {expandedStatus[id] && (
            <div className={styles.description_div}>
              {' '}
              <p className={styles.description_text}>{description}</p>
            </div>
          )}
        </div>
      </article>
      {children}
    </>
  )
}

export default FAQAccordion
