import { Props } from './Page.types'
import { t } from '../../translations'
import { Head } from '../Head'

const Page = ({
  title,
  children,
  description,
  image,
  url,
}: Props): JSX.Element => (
  <>
    <Head
      title={title || t('app.title')}
      description={description || t('app.description')}
      image={image}
      url={url}
    />
    {children}
  </>
)

export default Page
