import { default as NextHead } from 'next/head'

import { Props } from './Head.types'

const Head = ({ title, description, url }: Props): JSX.Element => (
  <NextHead>
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <title>{title}</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="description" content={description} />
    {/* Open graph */}
    <meta property="og:site_name" content="Pluggy" />
    {/* this check is to not get undefined values */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    {url && <meta property="og:url" content={`https://meu.pluggy.ai${url}`} />}
    <meta property="og:type" content="website" />

    <link rel="icon" sizes="96x96" href="/favicon-96x96.ico" />
    <link rel="icon" sizes="32x32" href="/favicon-32x32.ico" />
    <link rel="icon" sizes="16x16" href="/favicon-16x16.ico" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
  </NextHead>
)

export default Head
